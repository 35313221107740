import React, { useEffect, useState, useRef } from "react";
import { useSwipeable } from "react-swipeable";
import geraffe from "../../images/geraffe-right.webp";
import penguin from "../../images/penguin-right.webp";
import surikat from "../../images/surikat-right.webp";
import money from "../../images/money-wings.svg";
import biceps from "../../images/flexed-biceps.svg";
import clock from "../../images/alarm-clock.svg";
import Button from "../Button/Button";
import {
  SliderContent,
  GradientBg,
  SliderContainer,
  UpperText,
  MainText,
  SecondText,
  MiniInfo,
  MiniText,
} from "./Slider.styled";

const slidesDataRu = [
  {
    id: 1,
    imgDesktop: surikat,
    mainTextBefore: "Летнее предложение для ",
    mainTextHighlighted: "Интернета от 115 лей",
    mainTextAfter: " в месяц!",
    secondText:
      "Подключитесь к ультраскоростному интернету по оптоволокну от ARAX. Высокое качество и доступные цены для вашей семьи. Интернет-провайдер номер один в Кишинёве ждёт вас",
  },
  {
    id: 2,
    imgDesktop: penguin,
    mainTextBefore: "Летнее предложение для ",
    mainTextHighlighted: "Интернет + IPTV",
    mainTextAfter: " от 135 лей в месяц!",
    secondText:
      "Проведите лето 2024 года на высшем уровне, наслаждаясь ультраскоростным интернетом и исключительным качеством цифрового телевидения ARAX",
  },
  {
    id: 3,
    imgDesktop: geraffe,
    mainTextBefore: "Лучший пакет услуг ",
    mainTextHighlighted: "Интернет + IPTV",
    mainTextAfter: ' в предложениях "DUO"!',
    secondText:
      "Получите доступ к эксклюзивным каналам, любимым передачам и самым последним киноновинкам, все в высоком качестве изображения и звука",
  },
];

const slidesDataRo = [
  {
    id: 1,
    imgDesktop: surikat,
    mainTextBefore: "Ofertă de vară pentru ",
    mainTextHighlighted: "Internet de la 115 lei",
    mainTextAfter: " pe lună!",
    secondText:
      "Conectează-te la internetul ultrarapid pe fibră optică de la ARAX. Calitate superioară și prețuri accesibile pentru familia ta. Furnizorul de internet numărul 1 în Chișinău te așteaptă",
  },
  {
    id: 2,
    imgDesktop: penguin,
    mainTextBefore: "Ofertă de vară pentru ",
    mainTextHighlighted: "Internet + IPTV",
    mainTextAfter: " de la 135 lei pe lună!",
    secondText:
      "Petrece vara anului 2024 la superlativ, profitând de viteza ultrarapidă a Internetului și de calitatea excepțională a televiziunii digitale ARAX",
  },
  {
    id: 3,
    imgDesktop: geraffe,
    mainTextBefore: "Cel mai bun pachet de servicii ",
    mainTextHighlighted: "Internet + IPTV",
    mainTextAfter: ' în ofertele "DUO"!',
    secondText:
      "Beneficiază de acces la canale exclusive, emisiunile preferate și cele mai recente lansări de filme, toate într-o calitate superioară de imagini și sunet",
  },
];

const language = "ro";
const slidesData = language === "ru" ? slidesDataRu : slidesDataRo;

const Slider = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const intervalRef = useRef(null);

  // Detect if the device is mobile or desktop
  const detectIsMobile = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    const mobileRegex =
      /Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
    return mobileRegex.test(userAgent);
  };

  useEffect(() => {
    setIsMobile(detectIsMobile());
  }, []);

  // Functions for handling the slider
  const startSlider = () => {
    stopSlider();
    intervalRef.current = setInterval(nextSlide, 8000);
  };

  const stopSlider = () => {
    clearInterval(intervalRef.current);
  };

  const nextSlide = () => {
    startSlider();
    setCurrentSlide((current) => (current + 1) % slidesData.length);
  };

  const prevSlide = () => {
    startSlider();
    setCurrentSlide(
      (current) => (current - 1 + slidesData.length) % slidesData.length
    );
  };

  //  Handles starting and stopping the slider according to `mouseenter` and `mouseleave` events
  const handleMouseEnter = () => {
    stopSlider();
  };

  const handleMouseLeave = () => {
    startSlider();
  };

  // Configure the swipe
  const handlers = useSwipeable({
    onSwipedLeft: () => nextSlide(),
    onSwipedRight: () => prevSlide(),
    preventDefaultTouchmoveEvent: true,
    trackMouse: isMobile,
  });

  // Turns the slider on when the component is mounted and off when unmounted
  useEffect(() => {
    startSlider();
    return () => stopSlider();
  }, []);

  // Return the visual structure of the slider
  return (
    <SliderContainer
      className="slider-container"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      {...handlers}
    >
      {slidesData.map((slide, index) => (
        <div
          className={index === currentSlide ? "slide active" : "slide"}
          key={slide.id}
        >
          <GradientBg></GradientBg>
          <img src={slide.imgDesktop} alt={`Slide ${index + 1}`} />
          {index === currentSlide && (
            <SliderContent slideId={slide.id}>
              <UpperText>
                PROMO{" "}
                <strong>
                  {language === "ro" ? "3 luni gratuite" : "3 месяца бесплатно"}
                  !
                </strong>
              </UpperText>
              <MainText>
                {slide.mainTextBefore}
                <br></br>
                <span>{slide.mainTextHighlighted}</span>
                {slide.mainTextAfter}
              </MainText>
              <SecondText>{slide.secondText}</SecondText>
              <Button language={language} />
              <MiniInfo>
                <MiniText
                  colorBack={
                    slide.id === 3 || 2
                      ? "rgba(255, 255, 255, 0.5)"
                      : "rgba(255, 255, 255, 0.7)"
                  }
                >
                  <p>
                    {language === "ro"
                      ? "3 luni gratuite"
                      : "3 месяца бесплатно"}
                    !
                  </p>
                  <img src={money} alt="money-wings" />
                </MiniText>
                <MiniText
                  colorBack={
                    slide.id === 3 || 2
                      ? "rgba(255, 255, 255, 0.5)"
                      : "rgba(255, 255, 255, 0.7)"
                  }
                >
                  <p>
                    {language === "ro"
                      ? "Pachete de la 115 lei"
                      : "Пакеты от 115 лей"}
                    !
                  </p>
                  <img src={clock} alt="alarm-clock" />
                </MiniText>
                <MiniText
                  colorBack={
                    slide.id === 3 || 2
                      ? "rgba(255, 255, 255, 0.5)"
                      : "rgba(255, 255, 255, 0.7)"
                  }
                >
                  <p>
                    {language === "ro"
                      ? "Conectare în 24 de ore"
                      : "Подключение за 24 часа"}
                    !
                  </p>
                  <img src={biceps} alt="biceps" />
                </MiniText>
              </MiniInfo>
            </SliderContent>
          )}
        </div>
      ))}
      <div className="dots">
        {slidesData.map((_, index) => (
          <span
            key={index}
            className={index === currentSlide ? "dot active" : "dot"}
            onClick={() => setCurrentSlide(index)}
          ></span>
        ))}
      </div>
    </SliderContainer>
  );
};

export default Slider;
