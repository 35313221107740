import React from "react";
import Slider from "./components/Slider/Slider";

function App() {
  return (
    <div className="App">
      <Slider />
    </div>
  );
}

export default App;
