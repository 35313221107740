import styled from "styled-components";

export const SliderContainer = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  font-family: "Onest", sans-serif;
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }

  .slide {
    display: flex;
    opacity: 0;
    transition: opacity 1s ease-in-out;

    @media screen and (min-width: 1025px) {
      max-height: 600px;
    }

    @media screen and (max-width: 1024px) {
      flex-direction: column;
    }
  }

  .slide > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 20% 0%;
    z-index: 0;

    @media screen and (max-width: 1024px) {
      object-position: 50% 0%;
    }
  }

  .slide.active {
    display: flex;
    opacity: 1;
    z-index: 1;
  }

  .dots {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 2px;
    z-index: 5;
  }

  .dot {
    cursor: pointer;
    height: 12px;
    width: 12px;
    margin: 0 5px;
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 50%;
    display: block;
  }

  .dot.active {
    background-color: #fff;
  }
`;

export const GradientBg = styled.div`
  position: absolute;
  width: 800px;
  height: 480px;
  left: -10%;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 1;
  background: rgb(255, 255, 255);
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 1) 0%,
    rgba(39, 39, 111, 0) 50%
  );

  @media screen and (max-width: 1024px) {
    top: -10%;
    left: -50%;
  }
`;

export const SliderContent = styled("div")((props) => ({
  display: "flex",

  justifyContent: "space-evenly",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "25px",
  top: 0,
  padding: "50px 50px 100px",
  color: "#fff",
  maxWidth: "55%",
  zIndex: 2,

  "@media (max-width: 1600px)": {
    maxWidth: "70%",
  },

  "@media (max-width: 768px)": {
    padding: "30px 25px 60px",
    maxWidth: "100%",
  },
}));

export const UpperText = styled.p`
  display: inline-block;
  background-color: #fcf097;
  border-radius: 100px;
  padding: 10px 20px;
  font-size: 12px;
  margin: 0;
  color: black;
`;

export const MainText = styled.h1`
  font-size: 46px;
  font-family: "Onest", sans-serif;
  font-weight: 700;
  line-height: 58px;
  margin: 0;
  color: ${(props) => props.textColor || "black"};

  span {
    background-color: #d9eafd;
    border-radius: 6px;
    padding: 0px 16px;
  }

  @media screen and (max-width: 1024px) {
    font-size: 30px;
    line-height: normal;
    width: 100%;
  }
`;

export const SecondText = styled.p`
  max-width: 80%;
  font-size: 24px;
  line-height: 36px;
  margin: 0;
  color: ${(props) => props.textColor || "#140D27"};

  @media screen and (max-width: 1024px) {
    font-size: 22px;
  }
`;

export const MiniInfo = styled.div`
  display: flex;
  margin: 0;
  flex-direction: row;
  gap: 10px;
  margin-top: 24px;

  @media screen and (max-width: 1280px) {
    display: none;
  }
`;

export const MiniText = styled.div`
  display: flex;
  flex-direction: row;

  padding: 12px 16px;
  gap: 2px;
  align-items: center;
  justify-content: space-between;
  border-radius: 14px;
  color: black;
  background-color: ${(props) => props.colorBack || "rgba(255, 255, 255, 0.7)"};

  p {
    margin: 0;
    line-height: 15px;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    padding-right: 20px;
  }

  img {
    width: 28px !important;
    height: 28px !important;
  }
`;
