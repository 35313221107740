import styled from "styled-components";

export const StyledButton = styled.button`
  padding: 20px 36px;
  border: none;
  background-color: #130d25;
  color: #fff;
  cursor: pointer;
  border-radius: 100px;
  z-index: 5;
  font-size: 18px;
  line-height: 125%;
  font-family: "Onest", sans-serif;
  font-weight: 700;

  &:hover {
    background-color: #5f73f8;
  }

  @media screen and (max-width: 567px) {
    width: 100%;
  }
`;
