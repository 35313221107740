// Button.js
import React from "react";
import { StyledButton } from "./Button.styled";

const Button = ({ language }) => {
  const buttonText = language === "ru" ? "Оставить заявку" : "Conectare";

  const handleButtonClick = () => {
    const event = new CustomEvent("reactButtonClicked", {
      detail: { language },
    });
    window.dispatchEvent(event);
  };

  return (
    <StyledButton onClick={handleButtonClick}>
      {buttonText}
    </StyledButton>
  );
};

export default Button;
